@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('ProximaNova-Bold.eot');
  /* src: url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'), */
  src: url('ProximaNova-Bold.woff') format('woff'),
    url('ProximaNova-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('ProximaNova-Semibold.eot');
  /* src: url('ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'), */
  src: url('ProximaNova-Semibold.woff') format('woff'),
    url('ProximaNova-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Light';
  src: url('ProximaNova-Light.eot');
  /* src: url('ProximaNova-Light.eot?#iefix') format('embedded-opentype'), */
  src: url('ProximaNova-Light.woff') format('woff'),
    url('ProximaNova-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('ProximaNova-Regular.eot');
  /* src: url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'), */
  src: url('ProximaNova-Regular.woff') format('woff'),
    url('ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
