/* BREAKPOINTS */

/* xs: 0..575px    */
/* sm: 576..767px  */
/* md: 768..991px  */
/* lg: 992..1199px */
/* xl: 1200px+     */

/*
@media only screen and (min-width: 576px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}
*/

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
}
